export { useAuth } from "../redux/ducks/auth";
export { useForms } from "../redux/ducks/forms";
export { useRecCards } from "../redux/ducks/recCards";
export { useMyCards } from "../redux/ducks/myCards";
export { useHotel } from "../redux/ducks/hotel";
export { useFlights } from "../redux/ducks/flights";
export { useAdminCards } from "../redux/ducks/cards";
export { useUsers } from "../redux/ducks/users";
export { useReferral } from "../redux/ducks/referral";
export { useRegister } from "../redux/ducks/register";
export { useClassroom } from "../redux/ducks/classroom";
export { useResetPassword } from "../redux/ducks/resetPassword";
